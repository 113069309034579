<template>
  <div class="beacon_footer">
    <div class="footer">
      <div class="footer_block">
        <dl class="footer_item">
          <dt>产品信息</dt>
          <dd><router-link to="/tianshine">天食安</router-link></dd>
          <dd><router-link to="/dsh">大食慧</router-link></dd>
        </dl>

        <dl class="footer_item">
          <dt>关于碧耕</dt>
          <dd><router-link to="/introduction">公司简介</router-link></dd>
          <dd><router-link to="/history">发展历程</router-link></dd>
          <dd><router-link to="/culture">企业文化</router-link></dd>
          <dd><router-link to="/contact">联系我们</router-link></dd>
        </dl>
      </div>

      <div class="footer_block v_divider">
        <dl class="footer_item">
          <dt>友情链接</dt>
          <dd>
            <a href="http://www.samr.gov.cn/" target="_blank"
              >国家市场监督管理总局</a
            >
          </dd>
          <dd>
            <a href="http://www.chinacacm.org/" target="_blank"
              >中国教育后勤协会</a
            >
          </dd>
          <dd>
            <a href="http://www.chinahotel.org.cn/" target="_blank"
              >中国饭店协会</a
            >
          </dd>
          <dd>
            <a href="http://jyt.ah.gov.cn/" target="_blank">安徽省教育局</a>
          </dd>
        </dl>

        <dl class="footer_item">
          <dd>
            <a href="http://www.ahcas.com/" target="_blank">安徽烹饪协会</a>
          </dd>
          <dd>
            <a href="http://www.ahcyxh.com/" target="_blank">安徽餐饮协会</a>
          </dd>
        </dl>
      </div>

      <div class="footer_block">
        <dl class="footer_item">
          <dt>联系我们</dt>
          <dd>
            <a
              href="http://wpa.qq.com/msgrd?v=3&uin=1255322713&site=qq&menu=yes"
              target="_blank"
            >
              <img
                class="footer_icon"
                src="@/assets/images/footer_qq.png"
                alt="qq"
              />1255322713
            </a>
          </dd>
          <dd>
            <img
              class="footer_icon"
              src="@/assets/images/footer_email.png"
              alt="email"
            />4001278168
          </dd>
          <dd>
            <a href="tel:13349119898">
              <img
                class="footer_icon"
                src="@/assets/images/footer_phone.png"
                alt="phone"
              />13349119898
            </a>
          </dd>
          <dd>
            <a href="tel:0551-63671869">
              <img
                class="footer_icon"
                src="@/assets/images/footer_call.png"
                alt="call"
              />0551-63671869
            </a>
          </dd>
          <dd>
            <img
              class="footer_icon"
              src="@/assets/images/footer_location.png"
              alt="location"
            />安徽省合肥市高新区同创科技园5幢505室
          </dd>
        </dl>
        <dl class="footer_item">
          <dd>
            <img class="qrcode" :src="footer_qrcode" alt="二维码" />
            <span class="wechat">
              <img
                class="footer_icon"
                src="@/assets/images/footer_wechat.png"
                alt="wechat"
              />微信公众号
            </span>
          </dd>
        </dl>
      </div>

      <div class="footer_bottom">
        <div class="links">
          <router-link to="/">首页</router-link>
          <router-link to="/news">新闻动态</router-link>
          <router-link to="/cooperation">合作伙伴</router-link>
          <router-link to="/join">诚聘英才</router-link>
        </div>
        <div class="company">
          BEACON©碧耕软件 ICP备案许可号:皖ICP备14023345号-5
        </div>
      </div>
    </div>
    <div class="mini_footer">
      <ul class="footer_list">
        <li>
          <a
            href="http://wpa.qq.com/msgrd?v=3&uin=1255322713&site=qq&menu=yes"
            target="_blank"
          >
            <img :src="require('@/assets/images/footer_qq.png')" alt="" />
            1255322713
          </a>
        </li>
        <li>
          <a href="tel:13349119898">
            <img :src="require('@/assets/images/footer_phone.png')" alt="" />
            13349119898
          </a>
        </li>
        <li>
          <a href="mailto:47665538@qq.com">
            <img :src="require('@/assets/images/footer_email.png')" alt="" />
            47665538@qq.com
          </a>
        </li>
        <li>
          <img :src="require('@/assets/images/footer_location.png')" alt="" />
          安徽省合肥市高新区同创科技园5幢505室
        </li>
        <li>
          <a href="tel:400-1728-168">
            <img :src="require('@/assets/images/footer_call.png')" alt="" />
            400-1728-168
          </a>
        </li>
      </ul>
      <div class="qrcode_wrap">
        <img class="qrcode" :src="footer_qrcode" alt="" />
        <div>
          <img :src="require('@/assets/images/footer_wechat.png')" alt="" />
          <span> 微信公众号 </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { footer_qrcode } from "@/utils/images";
export default {
  name: "beacon-footer",
  data() {
    return {
      footer_qrcode,
    };
  },
};
</script>

<style lang="scss" scoped>
// 小屏幕页脚
.mini_footer {
  display: none;
  justify-content: space-between;
  background-color: #212930;
  font-size: 12vpx;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  padding: 20vpx;
  img {
    width: 10vpx;
    height: 12vpx;
    object-fit: contain;
  }
  .footer_list {
    li:not(:first-child) {
      margin-top: 9vpx;
    }
  }
  .qrcode_wrap {
    > div {
      margin-top: 8vpx;
    }
  }
  .qrcode {
    width: 80vpx;
    height: 80vpx;
  }
}
// 小屏幕页脚

// 大屏幕页脚
.footer {
  background-color: #212930;
  overflow: hidden;
  padding-top: 6%;
  white-space: nowrap;
  a {
    &:hover {
      color: #fff;
    }
  }
}

.footer_block:nth-child(1) {
  width: 26.07%;
  @media screen and (max-width: 1200px) {
    width: 43.9%;
  }
  float: left;
  .footer_item {
    float: left;
    &:nth-of-type(1) {
      margin-left: 23.99%;
    }
    &:nth-of-type(2) {
      margin-left: 19.95%;
    }
  }
}
.footer_block:nth-child(2) {
  width: 33.31%;
  @media screen and (max-width: 1200px) {
    width: 56.1%;
  }
  float: left;
  .footer_item {
    &:nth-of-type(1) {
      float: left;
      margin-left: 18.77%;
      @media screen and (max-width: 1300px) {
        width: 100%;
      }
      @media screen and (max-width: 1200px) {
        width: auto;
      }
    }
    &:nth-of-type(2) {
      float: left;
      margin-left: 12.45%;
      padding-top: 56px;
      @media screen and (max-width: 1300px) {
        width: 100%;
        margin-left: 18.77%;
        padding-top: 0;
      }
      @media screen and (max-width: 1200px) {
        width: auto;
        margin-left: 12.45%;
        padding-top: 56px;
      }
    }
  }
}

.footer_block:nth-child(3) {
  width: 40.62%;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  float: left;
  .footer_item {
    &:nth-of-type(1) {
      float: left;
      margin-left: 15.42%;
      @media screen and (max-width: 1200px) {
        margin-left: 10.53%;
      }
    }
    &:nth-of-type(2) {
      float: left;
      padding-top: 56px;
      margin-left: 7.63%;
    }
  }
}

.footer_block.v_divider {
  border-left: 1px solid #51565a;
  border-right: 1px solid #51565a;
}

.footer_item {
  dt {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #999999;
    margin-bottom: 40px;
  }
  dd {
    margin-bottom: 24px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #999999;
    a {
      color: #999999;
    }
  }
}

.footer_bottom {
  float: left;
  border-top: 1px solid #51565a;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 16px;
  margin-top: 47px;

  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 27px;
  color: #999999;

  .links {
    text-align: right;
    width: 47%;
    padding-right: 26px;
    padding-bottom: 24px;
    > a {
      display: inline-block;
      margin-top: 24px;
      margin-left: 16px;
    }
  }
  .company {
    text-align: left;
    width: 53%;
    margin-top: 24px;
    padding-left: 52px;
    word-break: break-all;
    white-space: initial;
  }
}

.qrcode {
  width: 80px;
  height: 80px;
}

.wechat {
  margin-top: 4px;
  font-size: 11px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
  opacity: 0.6;
  display: block;
  .footer_icon {
    width: 13px;
    height: 13px;
    vertical-align: -3px;
  }
}

.footer_icon {
  width: 16px;
  height: 16px;
  vertical-align: -2px;
  margin-right: 8px;
}
// 大屏幕页脚

@media screen and (max-width: 992px) {
  .footer {
    display: none;
  }
  .mini_footer {
    display: flex;
  }
}
</style>