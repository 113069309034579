import Vue from 'vue'
import VueRouter from 'vue-router'
import Default from "@/layout/Default";
import Home from "@/views/Home";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Default',
    component: Default,
    redirect: "/home",
    children: [{
      path: "home",
      name: "Home",
      component: Home
    }, {
      path: "news",
      name: "News",
      component: () => import("@/views/News")
    }, {
      path: "cooperation",
      name: "Cooperation",
      component: () => import("@/views/Cooperation")
    }, {
      path: "join",
      name: "Join",
      component: () => import("@/views/Join")
    }, {
      path: "introduction",
      name: "Introduction",
      component: () => import("@/views/Introduction")
    }, {
      path: "culture",
      name: "Culture",
      component: () => import("@/views/Culture")
    }, {
      path: "contact",
      name: "Contact",
      component: () => import("@/views/Contact")
    }, {
      path: "dsweet",
      name: "Dsweet",
      component: () => import("@/views/Dsweet")
    }, {
      path: "tianshine",
      name: "Tianshine",
      component: () => import("@/views/Tianshine")
    }, {
      path: "history",
      name: "History",
      component: () => import("@/views/History")
    }]
  }, {
    path: "*",
    name: "404",
    component: () => import("@/views/404")
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if(to.path === from.path) return;
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.path === "/contact") {
    store.commit("SET_NAV_BAR_THEME", "dark");
  } else {
    store.commit("SET_NAV_BAR_THEME", "transparent");
  }
  if(to.path !== from.path) {
    store.commit("SET_MNAVSHOW", false);
  }
  next();
});

export default router
