<template>
  <div class="layout_default">
    <beacon-header absolute="top"></beacon-header>
    <router-view></router-view>
    <beacon-footer></beacon-footer>
  </div>
</template>

<script>
export default {};
</script>