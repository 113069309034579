<template>
  <div class="home">
    <beacon-banner
      title="民以食为天 食以安为先"
      subTitle="Food safety is more than days"
    ></beacon-banner>
    <div class="title">产品介绍</div>
    <div class="content">
      <div class="home_card_list">
        <router-link
          v-for="(card, index) in cardList"
          :key="index"
          class="home_card_item"
          :to="card.url"
        >
          <div class="img_wrap">
            <img
              class="home_card_img"
              :src="card.imgSrc"
              :alt="card.title"
            />
          </div>

          <div class="desc">
            <div class="desc_title">{{ card.title }}</div>
            <div class="desc_content">
              {{ card.content }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { home_card_1, home_card_2 } from "@/utils/images";
export default {
  data() {
    return {
      cardList: [
        {
          url: "/tianshine",
          imgSrc: home_card_1,
          title: "天食安",
          content: `“天食安”智慧食安云平台采用SaaS云扩展架构，通过企业一线业务操作、集团中心管理和职能部门监管及社会
公众监督四端层层展开。一线人员食安现场操作如人员管控、验菜拍照、留样记录等等27个环节通过手机端APP
即可完成；二维码食堂一键显示营养配比、食材配方、供应来源等进行食材追溯；企业管理端可进行参数设置、
记录查询、违规预警等等，高效管理业务现场；集团中心端及时知晓预警信息、设置人员权限、大数据分析食安
管理状况；职能部门监管端可实时查看辖区内食安状态，进行线上抽检并针对食安隐患有针对性的检查。通过多
层次环环相扣，打造食安事前预警机制。`,
        },
        {
          url: "/dsweet",
          imgSrc: home_card_2,
          title: "大食慧",
          content: `碧耕软件通过近20年8000多家餐饮企业信息化建设的经验，运用目前国内流行的SaaS云扩展架构，自主研发兼具
门店运营、会员管理、供应链配送和集团管控四条餐饮业务线的智慧餐饮云平台----大食慧。历经数年耕耘，大食
慧已经形成了以餐饮ERP云集团平台、餐饮ERP云门店平台、供应链平台为主体，大食慧进销存、餐饮KDS、慧收
银、慧菜谱、慧外卖、大食慧桌边付、微慧员、慧菜谱、慧管家、慧商城为周边的大食慧智慧餐饮产品家族，服
务客户超过12000家，覆盖安徽、江苏、浙江、上海等多个省市区域。`,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.home_card_item {
  display: flex;
  background-color: #f5f8fa;
  margin-top: 2.08rem;
  &:last-child {
    margin-top: 6.25rem;
  }
  .img_wrap {
    overflow: hidden;
    transform: translateY(-2.08rem);
    width: 20rem;
    height: 20rem;
    flex-shrink: 0;
    margin-left: 2.08rem;
    .home_card_img {
      transition: 0.5s;
      &:hover {
        transform: scale(1.04);
      }
    }
  }

  .desc {
    margin-top: 5%;
    margin-bottom: 5%;
    padding-left: 6%;
    padding-right: 6%;
  }

  .desc_title {
    font-size: 24px;
    font-weight: 500;
    color: #333333;
  }

  .desc_content {
    font-size: 16px;
    margin-top: 3%;
    line-height: 28px;
  }
}

@media screen and (max-width: 992px) {
  .home_card_item {
    display: block;
    padding-left: 0;
    width: 100%;
    &:last-child {
      margin-top: 20vpx;
    }
    &:first-child {
      margin-top: 0;
    }
    .img_wrap {
      transform: translateY(0);
      width: 100%;
      height: auto;
      margin-left: 0;
    }
    .desc {
      padding-bottom: 20vpx;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 10vpx;
      padding-right: 10vpx;
    }
    .desc_title {
      font-size: 16vpx;
      padding: 20vpx 0;
      text-align: center;
    }
    .desc_content {
      margin-top: 0;
      font-size: 12vpx;
      font-weight: 400;
      line-height: 24vpx;
      color: #666666;
    }
  }
}
</style>