<template>
  <div class="pager">
    <router-link class="page_item" :to="{query: {...$route.query, currentPage: 1}}">«</router-link>
    <router-link
      class="page_item"
      :class="{ active: page === currentPage }"
      :to="{query: {...$route.query, currentPage: page}}"
      v-for="page in pageTotal"
      :key="page"
      >{{ page }}</router-link
    >
    <router-link class="page_item" :to="{query: {...$route.query, currentPage: pageTotal}}">»</router-link>
  </div>
</template>

<script>
export default {
  name: "beacon-pager",
  props: {
    // 记录总数
    total: {
      type: Number,
      default: 10,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    pageTotal() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  created() {},
  methods: {
    changePage(currentPage) {
      console.log(currentPage);
      // debugger
      // this.currentPage = currentPage;
      this.$router.push({query: {currentPage}});
      // this
    },
  },
};
</script>

<style lang="scss" scoped>
.pager {
  text-align: center;
  padding-top: 3.8%;
  word-break: break-all;
  .page_item.active {
    background-color: #4cb034;
    color: #fff;
  }
  a {
    margin-right: 1%;
    color: #333333;
    display: inline-block;
    margin-top: 1.3%;
    &:first-child {
      color: #999;
    }
    &:last-child {
      color: #999;
    }

    background: #ffffff;
    border: 1px solid #e1e1e1;
    opacity: 1;
    border-radius: 6px;
    padding: 14px 19px;
  }
}

@media screen and (max-width: 992px) {
  .pager {
    padding-top: 30vpx;
    a {
      margin-right: 5vpx;
      margin-top: 0;

      font-size: 11vpx;
      font-weight: 400;

      border-radius: 4vpx;
      padding: 10vpx 12vpx;
    }
  }
}
</style>