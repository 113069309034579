<template>
  <div class="beacon_header">
    <div class="large_header" :style="{ ...position, color, backgroundColor }">
      <h1 class="logo_wrap">
        <router-link class="nav_brand" to="/">
          <img class="logo" :src="logo" alt="logo" />
        </router-link>
      </h1>
      <nav class="header_nav">
        <dl
          :class="{ [activeClass]: currentIndex === index }"
          @mouseenter="navEnter(index)"
          @mouseleave="navLeave"
          v-for="(nav, index) in navList"
          :key="index"
        >
          <dt>
            <router-link class="nav_item" :to="nav.url"
              >{{ nav.title }}
              <img
                v-if="
                  nav.children &&
                  nav.children.length &&
                  $store.state.navBarTheme === 'transparent'
                "
                class="arrow_down"
                :src="
                  require(`@/assets/images/arrow_down_${
                    currentIndex === index ? 'dark' : 'light'
                  }.png`)
                "
                alt=""
              />
              <img
                v-if="
                  nav.children &&
                  nav.children.length &&
                  $store.state.navBarTheme === 'dark'
                "
                class="arrow_down"
                :src="require(`@/assets/images/arrow_down_${'dark'}.png`)"
                alt=""
              />
            </router-link>
          </dt>
          <dd v-if="nav.children && nav.children.length">
            <ul class="sub_menu">
              <li
                :class="{ sub_active: currentSubIndex === index }"
                @mouseenter="subNavEnter(index)"
                v-for="(subNav, index) in nav.children"
                :key="index"
              >
                <router-link class="nav_item" :to="subNav.url">{{
                  subNav.title
                }}</router-link>
              </li>
            </ul>
          </dd>
        </dl>
      </nav>
    </div>
    <div class="mini_header">
      <i
        class="side_menu iconfont icon-caidan"
        @click="openMnav"
        :style="{ color: $store.state.navBarTheme === 'dark' ? '#333' : '' }"
      ></i>
      <h1 class="logo_wrap">
        <router-link class="nav_brand" to="/">
          <img class="logo" src="@/assets/images/logo_1.png" alt="logo" />
        </router-link>
      </h1>
    </div>

    <transition name="fade">
      <div class="shadow" v-if="$store.state.mNavShow" @click="closeMnav"></div>
    </transition>

    <transition name="slide">
      <div class="m_nav" v-show="$store.state.mNavShow">
        <div class="nav_brand">
          <i class="close iconfont icon-guanbi" @click="closeMnav"></i>
          <router-link to="/">
            <img
              class="logo"
              :src="require('@/assets/images/logo_1.png')"
              alt="logo"
            />
          </router-link>
        </div>

        <nav>
          <dl
            :class="{ [activeClass]: currentIndex === index }"
            @click="navEnter(index)"
            v-for="(nav, index) in navList"
            :key="index"
          >
            <dt>
              <router-link class="nav_item" :to="{path: nav.url, query: nav.children && nav.children.length ? {...$route.query} : {}}"
                >{{ nav.title }}
                <img
                  v-if="nav.children && nav.children.length"
                  class="arrow_down"
                  :class="{ opened: currentIndex === index }"
                  :src="require(`@/assets/images/arrow_down_${'dark'}.png`)"
                  alt=""
                />
              </router-link>
            </dt>
            <dd
              :style="{
                height: getlastH(index),
              }"
            >
              <ul class="sub_menu" :ref="`subMenu`">
                <li
                  :class="{ sub_active: currentSubIndex === index }"
                  @click.stop="subNavEnter(index)"
                  v-for="(subNav, index) in nav.children"
                  :key="index"
                >
                  <router-link class="nav_item" :to="{path: subNav.url}">{{
                    subNav.title
                  }}</router-link>
                </li>
              </ul>
            </dd>
          </dl>
        </nav>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "beacon-header",
  props: {
    // 绝对定位位置
    absolute: {
      type: String,
      default: "",
    },
    // 固定定位位置
    fixed: {
      type: String,
      default: "",
    },
  },
  computed: {
    // logo图片
    logo() {
      return this.$store.state.navBarTheme === "transparent"
        ? require("@/assets/images/logo.png")
        : require("@/assets/images/logo_1.png");
    },
    // 主导航字体颜色
    color() {
      return this.$store.state.navBarTheme === "transparent" ? "#fff" : "#333";
    },
    // 背景色
    backgroundColor() {
      return this.$store.state.navBarTheme === "transparent"
        ? "transparent"
        : "#fff";
    },
    // 风格类
    activeClass() {
      return this.$store.state.navBarTheme === "transparent"
        ? "active"
        : "darkActive";
    },
    // 定位
    position() {
      if (this.absolute) {
        return {
          position: "absolute",
          [this.absolute]: 0,
        };
      } else if (this.fixed) {
        return {
          position: "fixed",
          [this.fixed]: 0,
        };
      }
    },
  },
  data() {
    return {
      currentIndex: null, // 当前激活的导航
      currentSubIndex: null, // 当前激活的子导航
      lastH: null, // 上一次展开的子导航的高度
      navList: [
        {
          title: "首页",
          url: "/",
        },
        {
          title: "产品信息",
          url: "",
          children: [
            {
              title: "天食安",
              url: "/tianshine",
            },
            {
              title: "大食慧",
              url: "/dsweet",
            },
          ],
        },
        {
          title: "新闻动态",
          url: "/news?currentTab=comp",
        },
        {
          title: "合作伙伴",
          url: "/cooperation",
        },
        {
          title: "诚聘英才",
          url: "/join",
        },
        {
          title: "关于碧耕",
          url: "",
          children: [
            {
              title: "公司简介",
              url: "/introduction",
            },
            {
              title: "发展历程",
              url: "/history",
            },
            {
              title: "企业文化",
              url: "/culture",
            },
            {
              title: "联系我们",
              url: "/contact",
            },
          ],
        },
      ],
    };
  },
  created() {},
  watch: {
    "$store.state.mNavShow"(mNavShow) {
      this.changeMnav(mNavShow);
    },
  },
  methods: {
    getlastH(index) {
      return this.currentIndex === index
        ? (this.$refs[`subMenu`][index].clientHeight || this.lastH) + "px"
        : null;
    },
    // 主导航进入
    navEnter(currentIndex) {
      if (this.currentIndex === currentIndex) {
        // debugger
        this.currentIndex = null;
        return;
      }
      this.currentIndex = currentIndex;
    },
    // 副导航进入
    subNavEnter(currentSubIndex) {
      this.currentSubIndex = currentSubIndex;
    },
    // 导航离开
    navLeave() {
      this.currentIndex = this.currentSubIndex = null;
    },
    changeMnav(mNavShow) {
      // debugger
      if (mNavShow) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    closeMnav() {
      
      this.changeMnav(false);
      this.$store.commit("SET_MNAVSHOW", false);
    },
    openMnav() {
      this.changeMnav(true);
      this.$store.commit("SET_MNAVSHOW", true);
    },
  },
};
</script>

<style lang="scss" scoped>
// 小导航栏
.mini_header {
  background-color: #fff;
  position: relative;
  height: 50vpx;
  display: none;
  justify-content: center;
  align-items: center;
  .side_menu {
    font-weight: bold;
    font-size: 16vpx;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .logo_wrap {
    width: 86vpx;
    height: 19vpx;
    .logo {
      width: 100%;
      height: 100%;
    }
  }
}
// 小导航栏

// 大导航栏
.large_header {
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-left: 2.6%;
  padding-right: 6.2%;
  padding-bottom: 10px;
  z-index: 2;
  .logo_wrap {
    width: 127px;
    height: 28px;
    .logo {
      width: 100%;
      height: 100%;
    }
  }
  .header_nav {
    flex: 1;
    justify-content: flex-end;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    line-height: 27px;
    display: flex;
    align-items: center;

    .nav_item {
      padding: 12px 10px;
      display: block;
      .arrow_down {
        width: 13px;
        height: 8px;
        margin-left: 8px;
        transition: 0.5s;
        &.opened {
          transform: rotateZ(180deg);
        }
      }
    }

    dl {
      margin-left: 4%;
      position: relative;
      z-index: 2;
      .sub_menu {
        background-color: #ffffff;
      }

      &.active {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        line-height: 27px;
        color: #333333;

        background-color: #ffffff;
        .sub_active {
          background-color: #efefef;
        }
        dd {
          display: block;
        }
      }
      &.darkActive {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        line-height: 27px;
        color: #333;
        background-color: #efefef;
        .sub_active {
          background-color: #efefef;
        }
        dd {
          display: block;
        }
      }
      dd {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding-top: 10px;
        z-index: 1;
        color: #333333;
        display: none;
      }
    }
  }
}

// 侧边栏导航
.shadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  display: none;
}

.m_nav {
  position: fixed;
  width: 250vpx;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: auto;
  display: none;

  .nav_brand {
    display: flex;
    padding: 18vpx 20vpx;
    justify-content: space-between;
    align-items: center;
    .close {
      color: #333;
      font-weight: bold;
      font-size: 16vpx;
    }
  }
  .logo {
    width: 90vpx;
    height: 20vpx;
    object-fit: contain;
  }
  .nav_item {
    display: block;
    padding: 25vpx 0 25vpx 50vpx;

    font-size: 16vpx;
    font-weight: 400;

    .arrow_down {
      width: 16vpx;
      height: 10vpx;
      object-fit: contain;
      transition: 0.5s;
      margin-left: 10vpx;
      &.opened {
        transform: rotateZ(180deg);
      }
    }
  }
  dd {
    height: 0;
    overflow: hidden;
    transition: 0.3s;
    padding-left: 40vpx;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.3s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}

.slide-enter-active,
.slide-leave-active {
  transition: 0.3s;
}
// 侧边栏导航

// 移动端适配
@media screen and (max-width: 992px) {
  .large_header {
    display: none !important;
  }
  .mini_header {
    display: flex !important;
  }
  .m_nav, .shadow {
    display: block;
  }
}
</style>