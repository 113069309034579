import Vue from "vue";

import BeaconBanner from "@/components/BeaconBanner";
import BeaconFooter from "@/components/BeaconFooter"; 
import BeaconPager from "@/components/BeaconPager"; 
import BeaconHeader from "@/components/BeaconHeader"; 

Vue.component(BeaconBanner.name, BeaconBanner);
Vue.component(BeaconFooter.name, BeaconFooter);
Vue.component(BeaconPager.name, BeaconPager);
Vue.component(BeaconHeader.name, BeaconHeader);

