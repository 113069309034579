import store from "@/store";

if (store.state.screenWidth <= 992) {
  var banner_1 = require("@/assets/images/m/banner_1.png");
  var home_china_map = require("@/assets/images/m/home_china_map.png");
  var home_card_1 = require("@/assets/images/m/home_card_1.png");
  var home_card_2 = require("@/assets/images/m/home_card_2.png");
  var footer_qrcode = require("@/assets/images/m/footer_qrcode.png");
  var banner_7 = require("@/assets/images/m/banner_7.png");
  var tianshine_logo = require("@/assets/images/m/tianshine_logo.png");
  var tianshine_1 = require("@/assets/images/m/tianshine_1.png");
  var advantage_1 = require("@/assets/images/m/advantage_1.png");
  var advantage_2 = require("@/assets/images/m/advantage_2.png");
  var advantage_3 = require("@/assets/images/m/advantage_3.png");
  var advantage_4 = require("@/assets/images/m/advantage_4.png");
  var customer_tianshine_1 = require("@/assets/images/m/customer_tianshine_1.png");
  var customer_tianshine_2 = require("@/assets/images/m/customer_tianshine_2.png");
  var customer_tianshine_3 = require("@/assets/images/m/customer_tianshine_3.png");
  var customer_tianshine_4 = require("@/assets/images/m/customer_tianshine_4.png");
  var customer_tianshine_5 = require("@/assets/images/m/customer_tianshine_5.png");
  var customer_tianshine_6 = require("@/assets/images/m/customer_tianshine_6.png");
  var customer_tianshine_7 = require("@/assets/images/m/customer_tianshine_7.png");
  var customer_tianshine_8 = require("@/assets/images/m/customer_tianshine_8.png");
  var customer_tianshine_9 = require("@/assets/images/m/customer_tianshine_9.png");
  var customer_tianshine_10 = require("@/assets/images/m/customer_tianshine_10.png");
  var customer_tianshine_11 = require("@/assets/images/m/customer_tianshine_11.png");
  var customer_tianshine_12 = require("@/assets/images/m/customer_tianshine_12.png");
  var customer_tianshine_13 = require("@/assets/images/m/customer_tianshine_13.png");
  var customer_tianshine_14 = require("@/assets/images/m/customer_tianshine_14.png");
  var customer_tianshine_15 = require("@/assets/images/m/customer_tianshine_15.png");
  var customer_tianshine_16 = require("@/assets/images/m/customer_tianshine_16.png");
  var customer_tianshine_17 = require("@/assets/images/m/customer_tianshine_17.png");
  var customer_tianshine_18 = require("@/assets/images/m/customer_tianshine_18.png");
  var customer_tianshine_19 = require("@/assets/images/m/customer_tianshine_19.png");
  var customer_tianshine_20 = require("@/assets/images/m/customer_tianshine_20.png");
  var banner_6 = require("@/assets/images/m/banner_6.png");
  var type_1 = require("@/assets/images/m/type_1.png");
  var type_2 = require("@/assets/images/m/type_2.png");
  var type_3 = require("@/assets/images/m/type_3.png");
  var type_4 = require("@/assets/images/m/type_4.png");
  var type_5 = require("@/assets/images/m/type_5.png");
  var type_6 = require("@/assets/images/m/type_6.png");
  var type_7 = require("@/assets/images/m/type_7.png");
  var type_8 = require("@/assets/images/m/type_8.png");
  var type_9 = require("@/assets/images/m/type_9.png");
  var type_10 = require("@/assets/images/m/type_10.png");

  var customer_1 = require("@/assets/images/m/customer_1.png");
  var customer_2 = require("@/assets/images/m/customer_2.png");
  var customer_3 = require("@/assets/images/m/customer_3.png");
  var customer_4 = require("@/assets/images/m/customer_4.png");
  var customer_5 = require("@/assets/images/m/customer_5.png");
  var customer_6 = require("@/assets/images/m/customer_6.png");
  var customer_7 = require("@/assets/images/m/customer_7.png");
  var customer_8 = require("@/assets/images/m/customer_8.png");
  var customer_9 = require("@/assets/images/m/customer_9.png");
  var customer_10 = require("@/assets/images/m/customer_10.png");
  var customer_11 = require("@/assets/images/m/customer_11.png");
  var customer_12 = require("@/assets/images/m/customer_12.png");
  var customer_13 = require("@/assets/images/m/customer_13.png");
  var customer_14 = require("@/assets/images/m/customer_14.png");
  var customer_15 = require("@/assets/images/m/customer_15.png");
  var customer_16 = require("@/assets/images/m/customer_16.png");
  var customer_17 = require("@/assets/images/m/customer_17.png");
  var customer_18 = require("@/assets/images/m/customer_18.png");
  var customer_19 = require("@/assets/images/m/customer_19.png");
  var customer_20 = require("@/assets/images/m/customer_20.png");

  var banner_2 = require("@/assets/images/m/banner_2.png");
  var join_egg = require("@/assets/images/m/join_egg.png");
  var join_dotsmap = require("@/assets/images/m/join_dotsmap.png");
  var banner_3 = require("@/assets/images/m/banner_3.png");
  var banner_4 = require("@/assets/images/m/banner_4.png");
  var company_1 = require("@/assets/images/m/company_1.png");
  var company_2 = require("@/assets/images/m/company_2.png");

  var history_0 = require("@/assets/images/m/history_0.png");
  var history_1 = require("@/assets/images/m/history_1.png");
  var history_2 = require("@/assets/images/m/history_2.png");
  var history_3 = require("@/assets/images/m/history_3.png");
  var history_4 = require("@/assets/images/m/history_4.png");

  var banner_5 = require("@/assets/images/m/banner_5.png");
  var culture_1 = require("@/assets/images/m/culture_1.png");
  var culture_2 = require("@/assets/images/m/culture_2.png");
  var culture_3 = require("@/assets/images/m/culture_3.png");
  var culture_4 = require("@/assets/images/m/culture_4.png");
} else {
  var banner_1 = require("@/assets/images/banner_1.png");
  var home_china_map = require("@/assets/images/home_china_map.gif");
  var home_card_1 = require("@/assets/images/home_card_1.png");
  var home_card_2 = require("@/assets/images/home_card_2.png");
  var footer_qrcode = require("@/assets/images/footer_qrcode.png");
  var banner_7 = require("@/assets/images/banner_7.png");
  var tianshine_logo = require("@/assets/images/tianshine_logo.png");
  var tianshine_1 = require("@/assets/images/tianshine_1.png");
  var advantage_1 = require("@/assets/images/advantage_1.png");
  var advantage_2 = require("@/assets/images/advantage_2.png");
  var advantage_3 = require("@/assets/images/advantage_3.png");
  var advantage_4 = require("@/assets/images/advantage_4.png");
  var customer_tianshine_1 = require("@/assets/images/customer_tianshine_1.png");
  var customer_tianshine_2 = require("@/assets/images/customer_tianshine_2.png");
  var customer_tianshine_3 = require("@/assets/images/customer_tianshine_3.png");
  var customer_tianshine_4 = require("@/assets/images/customer_tianshine_4.png");
  var customer_tianshine_5 = require("@/assets/images/customer_tianshine_5.png");
  var customer_tianshine_6 = require("@/assets/images/customer_tianshine_6.png");
  var customer_tianshine_7 = require("@/assets/images/customer_tianshine_7.png");
  var customer_tianshine_8 = require("@/assets/images/customer_tianshine_8.png");
  var customer_tianshine_9 = require("@/assets/images/customer_tianshine_9.png");
  var customer_tianshine_10 = require("@/assets/images/customer_tianshine_10.png");
  var customer_tianshine_11 = require("@/assets/images/customer_tianshine_11.png");
  var customer_tianshine_12 = require("@/assets/images/customer_tianshine_12.png");
  var customer_tianshine_13 = require("@/assets/images/customer_tianshine_13.png");
  var customer_tianshine_14 = require("@/assets/images/customer_tianshine_14.png");
  var customer_tianshine_15 = require("@/assets/images/customer_tianshine_15.png");
  var customer_tianshine_16 = require("@/assets/images/customer_tianshine_16.png");
  var customer_tianshine_17 = require("@/assets/images/customer_tianshine_17.png");
  var customer_tianshine_18 = require("@/assets/images/customer_tianshine_18.png");
  var customer_tianshine_19 = require("@/assets/images/customer_tianshine_19.png");
  var customer_tianshine_20 = require("@/assets/images/customer_tianshine_20.png");
  var banner_6 = require("@/assets/images/banner_6.jpg");
  var type_1 = require("@/assets/images/type_1.png");
  var type_2 = require("@/assets/images/type_2.png");
  var type_3 = require("@/assets/images/type_3.png");
  var type_4 = require("@/assets/images/type_4.png");
  var type_5 = require("@/assets/images/type_5.png");
  var type_6 = require("@/assets/images/type_6.png");
  var type_7 = require("@/assets/images/type_7.png");
  var type_8 = require("@/assets/images/type_8.png");
  var type_9 = require("@/assets/images/type_9.png");
  var type_10 = require("@/assets/images/type_10.png");

  var customer_1 = require("@/assets/images/customer_1.png");
  var customer_2 = require("@/assets/images/customer_2.png");
  var customer_3 = require("@/assets/images/customer_3.png");
  var customer_4 = require("@/assets/images/customer_4.png");
  var customer_5 = require("@/assets/images/customer_5.png");
  var customer_6 = require("@/assets/images/customer_6.png");
  var customer_7 = require("@/assets/images/customer_7.png");
  var customer_8 = require("@/assets/images/customer_8.png");
  var customer_9 = require("@/assets/images/customer_9.png");
  var customer_10 = require("@/assets/images/customer_10.png");
  var customer_11 = require("@/assets/images/customer_11.png");
  var customer_12 = require("@/assets/images/customer_12.png");
  var customer_13 = require("@/assets/images/customer_13.png");
  var customer_14 = require("@/assets/images/customer_14.png");
  var customer_15 = require("@/assets/images/customer_15.png");
  var customer_16 = require("@/assets/images/customer_16.png");
  var customer_17 = require("@/assets/images/customer_17.png");
  var customer_18 = require("@/assets/images/customer_18.png");
  var customer_19 = require("@/assets/images/customer_19.png");
  var customer_20 = require("@/assets/images/customer_20.png");

  var banner_2 = require("@/assets/images/banner_2.png");
  var join_egg = require("@/assets/images/join_egg.png");
  var join_dotsmap = require("@/assets/images/join_dotsmap.png");
  var banner_3 = require("@/assets/images/banner_3.png");
  var banner_4 = require("@/assets/images/banner_4.png");
  var company_1 = require("@/assets/images/company_1.png");
  var company_2 = require("@/assets/images/company_2.png");

  var history_0 = require("@/assets/images/history_0.png");
  var history_1 = require("@/assets/images/history_1.png");
  var history_2 = require("@/assets/images/history_2.png");
  var history_3 = require("@/assets/images/history_3.png");
  var history_4 = require("@/assets/images/history_4.png");

  var banner_5 = require("@/assets/images/banner_5.png");

  var culture_1 = require("@/assets/images/culture_1.png");
  var culture_2 = require("@/assets/images/culture_2.png");
  var culture_3 = require("@/assets/images/culture_3.png");
  var culture_4 = require("@/assets/images/culture_4.png");
}

export {
  banner_1,
  home_china_map,
  home_card_1,
  home_card_2,
  footer_qrcode,
  banner_7,
  tianshine_logo,
  tianshine_1,
  advantage_1,
  advantage_2,
  advantage_3,
  advantage_4,
  customer_tianshine_1,
  customer_tianshine_2,
  customer_tianshine_3,
  customer_tianshine_4,
  customer_tianshine_5,
  customer_tianshine_6,
  customer_tianshine_7,
  customer_tianshine_8,
  customer_tianshine_9,
  customer_tianshine_10,
  customer_tianshine_11,
  customer_tianshine_12,
  customer_tianshine_13,
  customer_tianshine_14,
  customer_tianshine_15,
  customer_tianshine_16,
  customer_tianshine_17,
  customer_tianshine_18,
  customer_tianshine_19,
  customer_tianshine_20,
  banner_6,
  type_1,
  type_2 ,
type_3 ,
type_4 ,
type_5 ,
type_6 ,
type_7 ,
type_8 ,
type_9 ,
type_10,
customer_1 ,
customer_2 ,
customer_3 ,
customer_4 ,
customer_5 ,
customer_6 ,
customer_7 ,
customer_8 ,
customer_9 ,
customer_10,
customer_11,
customer_12,
customer_13,
customer_14,
customer_15,
customer_16,
customer_17,
customer_18,
customer_19,
customer_20,
banner_2,
join_egg,
join_dotsmap,
banner_3,
banner_4,
company_1,
company_2,
history_0,
history_1,
history_2,
history_3,
history_4,
banner_5,

culture_1,
culture_2,
culture_3,
culture_4
}