<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_SCREEN_WIDTH", window.innerWidth)
    });
  }
}
</script>

<style lang="scss">
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
}

.p-0 {
  padding: 0 !important;
}

.img_wrap {
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// 标题
.title {
  font-size: 2rem;
  font-weight: 500;
  color: #333333;
  padding: 5% 0;
  text-align: center;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 992px) {
    font-weight: 500;
    font-size: 16vpx;
    justify-content: flex-start;
    padding: 40vpx 0 20vpx 15vpx;
    &::before {
      content: "";
      display: inline-block;
      height: 16vpx;
      width: 2vpx;
      background-color: #4CB034;
      margin-right: 10vpx;
    }
  }
}

// 内容
.content {
  padding: 0 5.2% 5.2%;
  @media screen and (max-width: 992px) {
    padding: 0 15vpx 40vpx;
  }
}

html {
  @media screen and (max-width: 1920px) {
    font-size: 24px;
  }
  @media screen and (max-width: 1600px) {
    font-size: 19px;
  }

  @media screen and (max-width: 1400px) {
    font-size: 17px;
  }

  @media screen and (max-width: 1366px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
  @media screen and (max-width: 992px) {
    font-size: 13px;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}
</style>