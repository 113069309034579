<template>
  <div class="banner" :style="bannerStyle">
    <template v-if="$route.path != '/home'">
        <img :src="bannerSrc" class="banner_img" />
    </template>
    <template v-else>
      <img
        :src="home_china_map"
        class="china_map"
      />
    </template>
    <div class="banner_text_wrap">
      <div class="zh_text">
        <span class="banner_text">{{ title.split(/\s+/)[0] }}</span>
        <span class="banner_text">{{ title.split(/\s+/)[1] }}</span>
      </div>
      <div class="en_text">
        <span
          class="banner_text"
          v-for="(item, index) in subTitle.split(/\s+/)"
          :key="index"
          >{{ item.toUpperCase() }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import BeaconHeader from "@/components/BeaconHeader";
import { home_china_map } from "@/utils/images";
export default {
  name: "beacon-banner",
  components: {
    BeaconHeader,
  },
  props: {
    minibannerSrc: {
      type: String,
      default: "",
    },
    // banner图链接
    bannerSrc: {
      type: String,
      default: "",
    },
    // banner图上的标题
    title: {
      type: String,
      default: "",
    },
    // banner图上的副标题
    subTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      home_china_map
    }
  },
  computed: {
    bannerStyle() {
      return this.bannerSrc ? null : { backgroundColor: "#031326" };
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  height: 0;
  padding-top: 39%;
  .banner_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .china_map {
    position: absolute;
    height: 82%;
    top: 90px;
    bottom: 36px;
    right: 12%;
    margin: auto;
  }
}

.banner_text_wrap {
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 160px;
  margin: auto;
  z-index: 1;
  transform: translateY(10px);
  color: #fff;
  span {
    display: inline-block;
  }
  .zh_text {
    .banner_text {
      font-size: 2rem;
      font-weight: 300;
      line-height: 2.5rem;
      margin-right: 20px;
    }
  }
  .en_text {
    .banner_text {
      font-size: 1.17rem;
      font-weight: 300;
      line-height: 1.5rem;
      margin-top: 0.8rem;
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 1200px) {
    left: 2.6%;
  }
}

// 移动端适配
@media screen and (max-width: 992px) {
  .banner {
    padding: 0;
    height: 195vpx;
    .china_map {
      width: 322vpx;
      height: 157vpx;
      top: 23vpx;
      bottom: 15vpx;
      right: 0;
    }
  }
  .banner_text_wrap {
    left: 15vpx;
    height: auto;
    transform: translateY(0);
    top: 83vpx;
    bottom: 83vpx;
    .zh_text {
      .banner_text {
        font-size: 16vpx;
        font-weight: 400;
        line-height: normal;
        margin-right: 10vpx;
      }
    }
    .en_text {
      .banner_text {
        font-size: 8vpx;
        font-weight: 400;
        line-height: normal;
        margin-top: 5vpx;
        margin-right: 5vpx;
      }
    }
  }
}
</style>