import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  state: {
    screenWidth: window.innerWidth,
    navBarTheme: location.hash === "#/contact" ? "dark" : "transparent",
    mNavShow: false
  },
  mutations: {
    SET_SCREEN_WIDTH(state, screenWidth) {
      state.screenWidth = screenWidth;
    },
    SET_NAV_BAR_THEME(state, navBarTheme) {
      state.navBarTheme = navBarTheme;
    },
    SET_MNAVSHOW(state, mNavShow) {
      state.mNavShow = mNavShow;
    }
  },
  plugins: [createPersistedState()],
  modules: {
  }
})

export default store
